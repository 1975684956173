* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #f1f1f1;
  color: #222;
}

h1 {
  color: purple;
}
.tracklist {
  width: 90vw;
  max-width: 1170px;
  margin: 5rem 5rem;
  display: grid;
  gap: 2rem;
}

@media screen and (min-width: 768px) {
  .tracklist {
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
  }
}

.track {
  background: #fff;
  border-radius: 1rem;
  padding: 1rem 2rem;
}

.track h1 {
  margin-top: 0.5rem;
}

.track h4 {
  color: rgb(171, 146, 4);
}

.track img {
  height: 238px;
  width: 350px;
}

.track p {
  color: #617d98;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}
